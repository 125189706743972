import axios from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';
import { ApiHcp } from './hcp';

export interface ApiProfile {
    id: string,
    firstName?: string,
    lastName?: string,
    hcp?: ApiHcp,
    role: string,
}

const rest = {
    getProfile: (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string): Promise<ApiProfile> => {
        return axios.post('auth/login', { emailAddress });
    },
    logout: (role: string): void => {
        if (localStorage.getItem('superToken')) {
            localStorage.removeItem('superToken');
            window.location.href = '/';
            return;
        }

        localStorage.removeItem('accessToken');

        if (role === 'HCP') {
            window.location.href = `${process.env.REACT_APP_API_BASE_URL}_saml.php?logout=1`;
        } else {
            window.location.href = '/admin';
        }
        // store.dispatch(setProfile());
    },
    hcpLogin: (): Promise<any> => {
        return axios.get('auth/saml');
    },
    superLogin: (userId: any) => {
        return axios.post(`auth/super-login`, { userId }).then(({ data }) => {
            localStorage.setItem('superToken', data.token);
            window.location.href = '/';
        });
    }
}

export default rest;
